<template>
  <div>
    <FilterOverview title="Daten-Upload" :showFilterInitially="false">
      <template #table="{ tableHeight }">
        <div class="card card-body">
          <div class="mb-2">
            <div class="mb-1">Bitte lade die Daten als CSV-Datei (.csv) hoch, um sie zu verarbeiten.</div>
            <div class="d-flex" style="justify-content: space-between">
              <b-input-group style="width: 50%" id="test">
                <b-input-group-prepend is-text id="foo">
                  <i class="fas fa-file-csv"></i>
                </b-input-group-prepend>
                <b-form-file
                  ref="formFileUpload"
                  id="form-csv-File"
                  accept=".csv"
                  :value="null"
                  :multiple="false"
                  placeholder="Wähle eine CSV-Datei aus"
                  @change="handleFileChange"
                  browse-text="Datei auswählen"
                ></b-form-file>
              </b-input-group>
              <!-- :disabled="checkRedRows" -->
              <b-button v-if="showUploadButton" @click="upload" variant="success">Upload der Daten</b-button>
            </div>
          </div>

          <div>
            Datenformat: {{ datenformatText }}
            <AgGridVue
              v-if="showGrid"
              :style="{ height: tableHeight - 65 + 'px' }"
              rowHeight="50px"
              class="ag-theme-alpine m-0 p-0"
              :rowData="rowData"
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :rowSelection="'multiple'"
              :rowMultiSelectWithClick="'true'"
              :rowClassRules="rowClassRules"
              @grid-ready="onGridReady"
              :statusBar="statusBar"
            ></AgGridVue>
          </div>
        </div>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import apiService from '@/core/common/services/api.service';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    StatusBarComponent,
  },
  data() {
    return {
      showGrid: false,
      showUploadButton: false,
      gridApi: null,
      gridColumnApi: null,
      columnDefs: [],
      rowData: [],
      statusBar: null,
      count: null,
      datenformatText: '',
      headers: [],
      defaultColDef: {
        flex: 1,
        filter: true,
        sortable: false,
        resizable: false,
        suppressMenu: true,
        filterParams: {
          defaultToNothingSelected: true,
        },
      },

      katalogbestellungHeaders: [
        { key: 'given_name', value: 'Vorname' },
        { key: 'family_name', value: 'Nachname' },
        { key: 'street_address', value: 'Straße und Hausnummer' },
        { key: 'postal_code', value: 'PLZ' },
        { key: 'address_locality', value: 'Ort' },
        { key: 'address_country', value: 'Land' },
        { key: 'email_address', value: 'E-Mail Adresse' },
      ],
      erwartetePaxHeaders: [
        { key: 'reisetermin', value: 'Reisetermin' },
        { key: 'erw_Pax', value: 'Erw. Pax' },
      ],
      rowClassRules: {
        'red-address': row => {
          return this.isDataInvalid(row.data);
        },
      },
    };
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.updateStatusBar(this.count);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = e => {
        const content = e.target.result;
        const fileData = this.parseCSV(content);
        this.importDataToGrid(fileData);
      };
      reader.readAsText(file);
    },
    parseCSV(content) {
      const separator = content.includes(';') ? ';' : ',';
      const rows = content.split('\n').filter(row => row.trim() !== '');
      return rows.map(row => row.split(separator).map(cell => cell.trim()));
    },
    richtigeHeaderAuswaehlen(csvHeaders) {
      const isKatalogbestellung = this.katalogbestellungHeaders.every(header =>
        csvHeaders.includes(header.key)
      );
      if (isKatalogbestellung) {
        return {
          type: 'Katalogbestellung',
          headers: this.katalogbestellungHeaders,
        };
      }

      const isErwartetePax = this.erwartetePaxHeaders.every(header => csvHeaders.includes(header.key));
      if (isErwartetePax) {
        return {
          type: 'Erwartete Pax',
          headers: this.erwartetePaxHeaders,
        };
      }

      return {
        type: 'Unbekanntes Datenformat',
        headers: [],
      };
    },

    importDataToGrid(fileData) {
      if (!fileData || fileData.length === 0) return;

      const csvHeaders = fileData[0];
      const headerInfo = this.richtigeHeaderAuswaehlen(csvHeaders);

      this.datenformatText = headerInfo.type;

      if (headerInfo.type !== 'Unbekanntes Datenformat') {
        if (headerInfo.type === 'Erwartete Pax') {
          this.showUploadButton = true;
        }
        this.showGrid = true;
        const columns = this.getColumnDefs(headerInfo.headers);
        this.columnDefs = columns;

        // header werden "abgeschnitten"
        const gridData = fileData.slice(1);
        this.rowData = this.dataAnordnen(gridData, csvHeaders, headerInfo.headers);
        this.count = this.rowData.length;

        this.gridApi.setRowData(this.rowData);
        this.updateStatusBar(this.rowData.length);
      } else {
        this.columnDefs = [];
        this.rowData = [];
      }
    },
    getColumnDefs(headers) {
      const dataColumns = headers.map(header => ({
        headerName: header.value,
        field: header.key,
        editable: true,
      }));

      return [...dataColumns];
    },

    dataAnordnen(gridData, csvHeaders, benoetigteHeader) {
      const mappedGrid = gridData.map(row => {
        // für jede row wird ein neues Objekt erstellt
        const rowObject = {};
        benoetigteHeader.forEach(header => {
          // hier werden die spalten den benötigten Headern zugeordnet
          const columnIndex = csvHeaders.indexOf(header.key);
          if (columnIndex >= 0 && columnIndex < row.length) {
            rowObject[header.key] = row[columnIndex];
          } else {
            rowObject[header.key] = '';
          }
        });
        return rowObject;
      });
      let compareFn = (a, b) => {
        const sortOrderA = this.isDataInvalid(a) ? 0 : 1;
        const sortOrderB = this.isDataInvalid(b) ? 0 : 1;
        return sortOrderA - sortOrderB;
      };
      const sortedGrid = mappedGrid.sort(compareFn);

      return sortedGrid;
    },
    updateStatusBar(displayedRowsCount) {
      if (!this.gridApi) return;
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');

      if (statusBarComponent) {
        statusBarComponent.setRowCount(displayedRowsCount);
        statusBarComponent.setOdataCount(this.count);
      }
    },

    async upload() {
      if (this.datenformatText === 'Erwartete Pax') {
        const updateValues = this.rowData.reduce((acc, curr) => {
          const reisetermin = curr['reisetermin'];
          const erwartetePax = parseInt(curr['erw_Pax']);
          if (reisetermin && !isNaN(erwartetePax)) {
            acc[reisetermin] = erwartetePax;
          }
          return acc;
        }, {});

        apiService.put('Reisetermin/bulkUpdateErwartetePax', { updateValues }).then(res => {
          console.log(res);
          this.toast('Erwartete Pax gespeichert!');
        });
      } else {
        this.toast('Katalogbestellungen können noch nicht hochgeladen werden!');
      }
      this.$refs['formFileUpload'].reset();
      this.showGrid = false;
      this.showUploadButton = false;
      this.datenformatText = '';
    },
    isDataInvalid(row) {
      if (!row) {
        return false;
      }
      return row.street_address && row.street_address.length > 25;
    },
  },
};
</script>
<style>
.red-address {
  background-color: #ffe2e5 !important;
}
</style>
